import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Alert,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios/axios";

const AddEditCategory = (props) => {
  const [image, setimage] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: props.category,
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter the name"),
      nameAr: Yup.string().required("Please enter the arabic name"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    if (props.selectedCategory) {
      handleEdit(values);
    } else {
      handleAdd(values);
    }
  };

  const handleEdit = (values) => {
    setLoadingSubmit(true);
    props.setError(null);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("nameAr", values.nameAr);
    image && formData.append("file", image);

    axios
      .put(`products/updateCategory/${props.selectedCategory._id}`, formData, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        props.setReload((prev) => !prev);
        props.setCategory({
          name: "",
          nameAr: "",
        });
        setSelectedFile([]);
        setimage(null);
        props.setSelectedCategory(null);
      })
      .catch((e) => {
        props.setError(e.response?.data?.message);
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  const handleAdd = (values) => {
    setLoadingSubmit(true);
    props.setError(null);
    let fdata = new FormData();
    fdata.append("file", image);
    fdata.append("name", values.name);
    fdata.append("nameAr", values.nameAr);

    axios
      .post("products/addCategory", fdata, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        props.setReload((prev) => !prev);
        setSelectedFile([]);
        props.setCategory({
          name: "",
          nameAr: "",
        });
      })
      .catch((e) => {
        props.setError(e.response.data?.message);
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };
  const uploadImage = (e) => {
    if (e.target.files.length > 0) {
      setimage(e.target.files[0]);
    } else {
      setimage(null);
    }
  };

  return (
    <>
      <Form
        className="row g-3 needs-validation"
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
        }}
      >
        {props.error && <Alert color="danger">{props.error}</Alert>}
        <Row style={{ width: "100%" }}>
          <Col xs={12} md={12}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Name
              </Label>
              <Input
                required
                id="name"
                className="form-control"
                type="text"
                placeholder="Name"
                value={props.category.name}
                invalid={validation.touched.name && validation.errors.name}
                onChange={(e) => {
                  props.setCategory({
                    ...props.category,
                    name: e.target.value,
                  });
                  validation.handleChange(e);
                }}
              />
              {validation.touched.name && validation.errors.name}
            </FormGroup>
          </Col>
          <Col xs={12} md={12}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                {"Name (AR)"}
              </Label>
              <Input
                required
                id="nameAr"
                className="form-control"
                type="text"
                placeholder="nameAr"
                value={props.category.nameAr}
                invalid={validation.touched.nameAr && validation.errors.nameAr}
                onChange={(e) => {
                  props.setCategory({
                    ...props.category,
                    nameAr: e.target.value,
                  });
                  validation.handleChange(e);
                }}
              />
              {validation.touched.nameAr && validation.errors.nameAr}
            </FormGroup>
          </Col>
          <Col xs={12} md={12}>
            <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
              Image
            </Label>
            <Input
              id="image"
              className="form-control"
              type="file"
              placeholder="Image"
              onChange={uploadImage}
            />
          </Col>
        </Row>
        <div className="col-12">
          <button
            className="btn btn-primary"
            type={loadingSubmit ? null : "submit"}
          >
            {loadingSubmit ? (
              <Spinner size={"sm"} color="secondary" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Form>
    </>
  );
};
AddEditCategory.propTypes = {
  selectedCategory: PropTypes.any,
  setSelectedCategory: PropTypes.any,
  category: PropTypes.any,
  setCategory: PropTypes.any,
  setReload: PropTypes.any,
};

export default AddEditCategory;
